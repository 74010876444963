import React, { useRef } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
// styles
import clsx from 'clsx';
import itemStyles from '../components/item/cases.module.css';
import styles from './i-fit.module.css';
// components
import Header from '../components/item/header';
import Layout from '../components/item/layout';
import Team from '../components/team';
import Resume from '../components/resume';
import Blockquote from '../components/blockquote';
import Booking from '../../feedback';
import Recognition from 'components/recognition';
//other
import { meta } from '../../../metaData';
import GetConsultation from 'components/free-consultation';
import Identificators from 'components/googleAnalyticsIdentificators';
import FitnessAppCalculatorBanner from 'components/fitness-app-calculator/fitness-app-calculator-banner';

function IFitCase() {
  const bookingRef = useRef<HTMLDivElement | null>(null);

  const data = useStaticQuery(graphql`
    query {
      screen1: file(relativePath: { eq: "i-fit-screen-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen2: file(relativePath: { eq: "i-fit-screen-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen3: file(relativePath: { eq: "i-fit-screen-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen4: file(relativePath: { eq: "i-fit-screen-4.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen5: file(relativePath: { eq: "i-fit-screen-5.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen6: file(relativePath: { eq: "i-fit-screen-6.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen7: file(relativePath: { eq: "i-fit-screen-7.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen8: file(relativePath: { eq: "i-fit-screen-8.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      michelleBowser: file(relativePath: { eq: "michelle-bowser.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const handleClickCTA = () => {
    if (bookingRef.current !== null) {
      bookingRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  return (
    <Layout
      score={['Fitness software', 'USA', '2021', 'Ongoing']}
      navBtn="#14181A"
      className={styles.header}
      contentClassName={styles.content}
      headerTitle="Transforming fitness with API integrations"
      subTitle="How Brocoders integrated Strava, Garmin, and Google Fit to help people reach their fitness goals"
      metaData={meta.iFit}
      scrollRef={bookingRef}
    >
      <div className={itemStyles.inner}>
        <p className={itemStyles.preamble}>
          Our client is a major training equipment manufacturer who develops fitness software that
          helps people work out—at home or on the go — to achieve their fitness goals, in a
          community of like-minded people.
        </p>
        <p className={itemStyles.paragraph}>
          This company has been developing its products since 1989. Initially, they only made
          exercise equipment, but with the advance of technology, the company began to provide video
          training, and later, developed fitness software.
        </p>
        <p className={itemStyles.paragraph}>
          The software gives users access to trainers, classes, and a community, making workouts
          more interesting. The equipment is deeply integrated with the software, and as a result,
          gives customers pleasure from working out.
        </p>
        <h2 className={itemStyles.title}>The situation</h2>
        <p className={itemStyles.paragraph}>
          The company has already completed the development of software for their equipment, as well
          as with the possibility of training on the equipment of competitors. Buyers could already
          work with a trainer, choose classes worldwide, as well as run/swim/race along the most
          beautiful routes in the world. It was already a full-fledged working product, which had
          tens of thousands of users.
        </p>
        <p className={itemStyles.paragraph}>
          But physical activity isn't limited to training on the simulator. So, to achieve the best
          results, it’s necessary to factor in nutrition, sleep, activity, and other health
          indicators like heart rate and respiration. Luckily data from apps and wearables can
          provide this valuable information. Therefore, our client’s business analysts decided to
          integrate with Strava and Garmin based on user requests.
        </p>
        <p className={itemStyles.paragraph}>
          After Strava and Garmin, Brocoders proposed to integrate with Google Fit because this app
          is also very popular. The client agreed, and after implementing these integrations, we
          expanded the list to include OuraRing and Amazon Halo. And if everything is good, we will
          expand further.
        </p>
        <p className={itemStyles.paragraph}>
          More information about integration with Google Fit and Apple Health API and how it can
          benefit your app -{' '}
          <Link className={styles.link} to="/fitness-app-api-integrations/">
            {' '}
            read here
          </Link>
          .
        </p>
        <h2 className={itemStyles.title}>Working process</h2>
        <p className={itemStyles.paragraph}>
          To achieve the best result, we started by examining existing APIs, building a mapping for
          synchronizing data from different sources and eliminating data duplication.
        </p>
        <p className={itemStyles.paragraph}>
          The most important part of the working process was building the team's collaboration. This
          was the client’s first experience of working with an extended team.
        </p>
        <p className={itemStyles.paragraph}>
          We didn't have to reinvent the wheel to meet the challenges in this project. All it took
          was good old-fashioned communication, clarification of tasks and goals, knowledge
          exchange, and of course, Jira.
        </p>
        <p className={itemStyles.paragraph}>
          The data exchange with other services was implemented in a separate module, which made it
          possible to simplify testing and interaction between teams.
        </p>
        <p className={itemStyles.paragraph}>
          Our QA engineers employed best practices to ensure the app launch was a success.
        </p>
      </div>
      {/* <GetConsultation
        containerClassName={styles.ctaContainer}
        title="Get a consultation how we can empower your app with Google Fit and Apple Health API"
        linkText="Let's talk"
        linkId={Identificators.SCHEDULE_A_CALL_BANNER}
        onClick={handleClickCTA}
      /> */}
      <Blockquote
        img={data.michelleBowser.childImageSharp.fluid}
        isRating
        text={`“They worked very closely together with our team and were involved in our planning processes.”`}
        linkText="Read full review on Clutch"
        downloadReviewLink="https://clutch.co/profile/brocoders?_gl=1%2Aqphzju%2A_ga%2AMTg5ODQwMjc4Ny4xNjQ5NjY3MTY4%2A_ga_D0WFGX8X3V%2AMTY1MDQ1MzQzOC45MS4xLjE2NTA0NTM0NDQuNTQ.#summary"
        author={
          <React.Fragment>
            <strong>Michelle Bowser </strong> <br />
            Senior Director of Development Experience
          </React.Fragment>
        }
      />
      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>Key challenges</h2>
        <p className={itemStyles.paragraph}>
          Strava API usage is limited on a per-application basis using both 15-minute and daily
          request limits. The default rate limit allows 100 requests every 15 minutes, with up to
          1,000 requests per day.
        </p>
        <p className={itemStyles.paragraph}>
          As the number of users grew, there was a problem with the limits on the number of
          requests. Our team responded in time and solved the problem by spacing out requests. That
          is, we increased the frequency of requests but decreased the number of requests at a given
          time.
        </p>
        <p className={itemStyles.paragraph}>
          The second challenge was related to the over-sized data packets.
        </p>
        <p className={itemStyles.paragraph}>
          Our task was to draw diagrams in a picture that we downloaded from Strava. Therefore, the
          data packet size was exceeded due to the exchange of graphic information. Because,
          according to the Strava guide, charts are drawn on top of pictures. The problem was solved
          by creating an assembly.
        </p>
        <h2 className={itemStyles.title}>Delivered solution</h2>
        <p className={itemStyles.paragraph}>
          The Brocoders team developed a reliable integration for managing numerous activities
          during workouts of any type.
        </p>
        <p className={itemStyles.paragraph}>
          We provided full-cycle maintenance starting from the initial idea to launch.
        </p>
        <p className={itemStyles.paragraph}>The client received:</p>
        <ul className={clsx(itemStyles.bullets, styles.bulletsColor)}>
          <li>
            <h3 className={itemStyles.subtitle}>New users</h3>
            <p className={itemStyles.paragraph}>
              Apple Health and Google Fit advertise apps on their homepages, this additional
              advertising channel attracts new users every day.
            </p>
          </li>
          <li>
            <h3 className={itemStyles.subtitle}>New data</h3>
            <p className={itemStyles.paragraph}>
              After{' '}
              <Link className={styles.link} to="/fitness-app-api-integrations/">
                connecting the app to a fitness service like Google Fit or Strava via API
              </Link>
              , the customer got access to large amounts of valuable data – without any additional
              development costs. Subsequently, this also provides an opportunity to build multiple
              additional features based on big data.
            </p>
          </li>
          <li>
            <h3 className={itemStyles.subtitle}>Increased interaction with the application</h3>
            <p className={itemStyles.paragraph}>Users can analyze all activities on one app.</p>
          </li>
        </ul>
      </div>
      <div className={styles.screensContainer}>
        <Img fluid={data.screen2.childImageSharp.fluid} className={styles.screen} alt="" />
        <div className={itemStyles.inner}>
          <p className={styles.caption}>How activity looks in the Fitness app</p>
        </div>
        <Img fluid={data.screen3.childImageSharp.fluid} className={styles.screen} alt="" />
        <div className={itemStyles.inner}>
          <p className={styles.caption}>Activities that came from Google Fit to the Fitness app</p>
        </div>
        <Img fluid={data.screen4.childImageSharp.fluid} className={styles.screen} alt="" />
        <div className={itemStyles.inner}>
          <p className={styles.caption}>This is how the activity was signed into Strava</p>
        </div>
        <Img fluid={data.screen5.childImageSharp.fluid} className={styles.screen5} alt="" />
        <div className={itemStyles.inner}>
          <p className={styles.caption}>This is how the activity was signed into Google Fit</p>
        </div>
        <Img fluid={data.screen6.childImageSharp.fluid} className={styles.screen} alt="" />
        <div className={itemStyles.inner}>
          <p className={styles.caption}>Activities was created in Garmin</p>
        </div>
        <Img fluid={data.screen7.childImageSharp.fluid} className={styles.screen} alt="" />
        <p className={styles.caption}>Synchronization with Strava</p>
        <div className={styles.fitnessCalcBanner}>
          <FitnessAppCalculatorBanner color="blue" />
        </div>
        <div className={itemStyles.inner}>
          <h2 className={clsx(itemStyles.title, styles.teamTitle)}>Our team</h2>
          <p className={itemStyles.paragraph}>
            We provided Our client with a dedicated team of highly experienced Node.js developer, QA
            engineers and PM. Work process was based on best agile practices when every release was
            tested in several iterations before publishing to production. Through the efficient
            collaboration of Brocoders and the client’s team, we managed to deliver well tested
            bug-free version of integration to the customers.
          </p>
          <Team
            color="#187DE0"
            list={[
              { count: 3, title: 'Backend\nengineers' },
              { count: 1, title: 'QA\nengineer' },
              { count: 1, title: 'Project\nmanager' },
            ]}
          />
          <Resume services={['dedicatedTeam']} technology={['nodejs']} />
        </div>
      </div>
      <Img fluid={data.screen8.childImageSharp.fluid} className={itemStyles.container} alt="" />
      <div className={itemStyles.inner}>
        <h2 className="title">Why choose us</h2>
        <Recognition />
      </div>
    </Layout>
  );
}

export default IFitCase;
