import React, { FC } from 'react';
import clsx from 'clsx';
import { Link } from 'gatsby';
// assets
import img from './img.png';
// styles
import styles from './styles.module.css';

interface IProps {
  color?: 'blue';
}

const Routes = require('../../routes').Routes;

const FitnessAppCalculatorBanner: FC<IProps> = ({ color }) => {
  return (
    <div className={clsx(styles.wrapper, { [styles[color]]: !!color })}>
      <div className={clsx('inner', styles.container)}>
        <div className={styles.text}>
          <h3 className={styles.title}>Fitness App Cost Calculator</h3>
          <Link
            to={Routes.FITNESS_APP_CALCULATOR}
            style={{ maxWidth: '240px' }}
            className="btn btn_60 btn__red"
          >
            Get an estimation
          </Link>
        </div>
        <div className={styles.img}>
          <img src={img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default FitnessAppCalculatorBanner;
